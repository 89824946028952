export class ApiError extends Error {
  constructor(errorCode) {
    super(errorCode);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    } else {
      Object.defineProperty(this, 'stack', {
        enumerable: false,
        writable: false,
        value: new Error(this.message).stack,
      });
    }

    this.name = `api error: ${errorCode}`;
  }
}
