export class NotFoundError extends Error {
  constructor(...args) {
    super(...args);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError);
    } else {
      Object.defineProperty(this, 'stack', {
        enumerable: false,
        writable: false,
        value: new Error(this.message).stack,
      });
    }

    this.name = 'resource not found';
  }
}
